import React, { Component } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Helmet } from "react-helmet";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import BG from "./bg.jpeg";



class Histoire extends Component {
  render() {

    return (
      <Jumbotron
        style={{
          color: "#efefef",
          fontFamily: "arial",
          backgroundImage: `url(${BG}), linear-gradient(45deg,#aaa 50.9%,#000 60%)`,
          filter: "grayscale(80%)",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundBlendMode: "multiply",
          backgroundRepeat: 'no-repeat',
          marginBottom: "0px"
        }} fluid
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>notesur20.com - L'histoire de la Note sur 20</title>
          <meta name="description" content="Article : L'Histoire de la note sur 20" />
        </Helmet>
        <Container>
          <div>
            <h1>L'Histoire de la Note sur 20</h1>

            <p>La note sur 20 est un système de notation largement utilisé dans le monde académique pour évaluer la performance des élèves, des étudiants et même des professionnels. Mais d'où vient cette échelle de notation ? Dans cette page, nous plongerons dans l'histoire fascinante de la note sur 20, en explorant son origine, son évolution au fil du temps, et son rôle dans l'éducation moderne.</p>
          </div><div>
            <h2>Origines de la Note sur 20</h2>

            <p>L'histoire de la note sur 20 remonte au début du système éducatif moderne en France au XIXe siècle. En 1829, le mathématicien français Adolphe Quetelet a proposé l'idée de noter les performances des étudiants sur une échelle de 20 points. Ce système de notation a été adopté pour la première fois par l'École Polytechnique de Paris en 1830.</p>
          </div><div>
            <h2>Évolution au Fil du Temps</h2>

            <p>Au fil des décennies, la note sur 20 s'est répandue dans d'autres institutions éducatives françaises et a été adoptée par de nombreux pays francophones. Cependant, la façon dont elle est utilisée et interprétée peut varier d'un endroit à l'autre. Certaines écoles attribuent des notes sous forme de pourcentage, tandis que d'autres se conforment strictement à l'échelle de 20 points.</p>
          </div><div>
            <h2>Le Système de Notation Actuel</h2>

            <p>De nos jours, la note sur 20 est encore largement utilisée en France, en Belgique, en Suisse, au Luxembourg et dans d'autres pays francophones. Elle est couramment employée pour évaluer la performance scolaire et universitaire. Cependant, de plus en plus d'institutions adoptent des systèmes de notation différents, notamment des notes sur 10, des GPA, ou des notes A-F, pour mieux s'aligner avec les normes internationales.</p>
          </div><div>
            <h2>Le Rôle Actuel de la Note sur 20</h2>

            <p>Malgré les changements dans le paysage éducatif mondial, la note sur 20 continue de jouer un rôle important dans la notation des élèves. Elle fournit un moyen relativement simple et clair d'évaluer les performances, mais elle est également critiquée pour sa simplicité et sa subjectivité potentielle. De nombreuses discussions sont en cours sur l'avenir de la notation, à mesure que l'éducation évolue pour répondre aux besoins changeants de la société.</p>
          </div><div>
            <p><strong>Conclusion</strong></p>

            <p>La note sur 20 a une histoire riche qui remonte à presque deux siècles en France. Bien qu'elle soit encore largement utilisée dans certains pays, elle fait face à des défis et à des critiques. L'histoire de la note sur 20 reflète l'évolution de l'éducation moderne et soulève des questions importantes sur la manière dont nous évaluons les performances académiques aujourd'hui. Elle continue d'être un sujet de débat dans le monde de l'éducation.</p>
          </div>
          <Row>
            <Col xs lg="6">
              <LinkContainer
                to="/les-alternatives-a-la-note-sur-20.html"
                style={{ textAlign: "center" }}
                onClick={() => {
                  window.open('//greewepi.net/4/6861104', '_blank', 'noopener,noreferrer');
                }}
              >
                <Nav.Link to="/les-alternatives-a-la-note-sur-20.html">
                  <Button variant="danger" style={{ width: "80%" }}>
                    Continuez la lecture vers <br /> "les alternatives à la note sur 20"
                  </Button>
                </Nav.Link>
              </LinkContainer>
            </Col>
            <Col xs lg="6">
              <LinkContainer
                to="/convertisseur-de-note-sur-20.html"
                style={{ textAlign: "center" }}
                onClick={() => {
                  window.open('//greewepi.net/4/6861104', '_blank', 'noopener,noreferrer');
                }}
              >
                <Nav.Link to="/convertisseur-de-note-sur-20.html">
                  <Button variant="warning" style={{ width: "80%" }}>
                    Accédez à notre convertisseur en ligne <br />de note sur 20
                  </Button>
                </Nav.Link>
              </LinkContainer>
            </Col>


          </Row>

        </Container>
      </Jumbotron>
    );
  }
}

export default Histoire;
