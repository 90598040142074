import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Jumbotron from 'react-bootstrap/Jumbotron';
import BG from "./bg.jpeg";
import { Helmet } from "react-helmet";


const Legal = () => {
  return (

    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>notesur20.com - Legal</title>
        <meta name="description" content="Note sur 20 informations légales et politique de confidentialité des données" />
      </Helmet>
      <Jumbotron
        style={{
          color: "#efefef",
          fontFamily: "arial",
          backgroundImage: `url(${BG}), linear-gradient(45deg,#aaa 50.9%,#000 60%)`,
          filter: "grayscale(80%)",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundBlendMode: "multiply",
          backgroundRepeat: 'no-repeat',
          marginBottom: "0px"
        }} fluid
      >

        <Container className="container-fluid" style={{ backgroundBlendMode: "difference" }}>
          <Row>
            <Col sm={8}>
              <h1 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                display: "flex",
                textTransform: "uppercase"
              }}>Mentions légales <br /> <br /> notesur20.com</h1>
              <br />
              <h3 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}><span style={{ borderBottom: "1px #fff solid" }}>Identification et objet du site</span></h3>
              <p>

                Le site internet notesur20.com à pour objet pédagogique de proposer un outil de conversion pour rapporter n'importe quelle note sur 20 points.
                <br />Le site est édité par Mr. Dubilly.

              </p>
              <h3 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                display: "flex",
                textTransform: "uppercase"
              }}><span style={{ borderBottom: "1px #fff solid" }}>Hebergement </span></h3>
              <p>
                Le site Internet <b>notesur20.com</b> est hébergé par : <br >
                </br>Netlify, dont le siège social est situé 2325 3rd Street, Suite 296, San Francisco, California 94107.
                <br />
                URL du site : www.netlify.com/
                <br />
                Contact : www.netlify.com/contact/
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <h1 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                display: "flex",
                textTransform: "uppercase"
              }}>Politique de confidentialité</h1><br />
              <h2 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}>1. Collecte d'informations personnelles</h2>
              <p>Nous ne recueillons aucune information personnelle (votre nom, votre adresse e-mail, votre numéro de téléphone, etc.) lorsque vous utilisez notre site Web.</p>
              <h2 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}>2. Utilisation des informations</h2>
              <p>Nous utilisons les informations que nous collectons pour vous fournir nos services, répondre à vos questions et améliorer notre site Web.</p>
              <h2 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}>3. Partage des informations</h2>
              <p>Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement.</p>
              <h2 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}>4. Sécurité</h2>
              <p>Nous prenons des mesures de sécurité pour protéger vos informations personnelles contre toute utilisation non autorisée ou tout accès non autorisé.</p>
              <h2 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}>5. Cookies</h2>
              <p>Nous utilisons des cookies pour améliorer l'expérience de nos utilisateurs sur notre site Web. Vous pouvez gérer les cookies dans les paramètres de votre navigateur.</p>
              <h2 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}>6. Modifications de la politique de confidentialité</h2>
              <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page.</p>
              <h2 style={{
                letterSpacing: "3px",
                color: "#fff",
                fontWeight: "bolder",
                marginBottom: "30px",
                marginTop: "15px",
                textTransform: "uppercase",
                display: "flex",
              }}>7. Contact</h2>
              <p>Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter à l'adresse suivante : wilydub@gmail.com</p>
              <p>Dernière mise à jour : 04/10/2023</p>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </div>

  );
};
export default Legal;
