import React, { useState } from 'react';
import { Row, Col, Container} from "react-bootstrap";
import { PiNumberCircleOneFill, PiNumberCircleTwoFill  } from "react-icons/pi";
import Jumbotron from "react-bootstrap/Jumbotron";
import SatisfactionForm from "./Satisfaction";
import { Helmet } from "react-helmet";
import BG from "./bg.jpeg";



function test(note, barem) {
  let res = false;
  if (note && note !== '' && barem !== false) {
    res = (note / barem * 20);
    if (isFinite(res)) {
      return {
        result: res.toFixed(2) + '/20',
      }
    } else {
      return {
        result: "Votre résultat ici",
      }
    }
  } else {
    return {
      result: "Votre résultat ici",
    }
  }
}

function ConvertNoteSur20() {
  // État local pour gérer les valeurs des inputs
  const [note, setInputNote] = useState('');
  const [barem, setInputBarem] = useState('');

  // Gestionnaire d'événements pour mettre à jour la valeur du premier input
  const handleInputNoteChange = (event) => {
    setInputNote(event.target.value);
    test(note, barem);
  };

  // Gestionnaire d'événements pour mettre à jour la valeur du deuxième input
  const handleInputBaremChange = (event) => {
    setInputBarem(event.target.value);
    test(note, barem);
  };

  const jsonLD = {
    "@context": "http://schema.org",
    "@type": "WebApplication",
    "name": "Convertisseur de notes sur 20",
    "description": "Comment convertir une note en d'autres échelles de notation vers une note sur 20.",
    "url": "https://notesur20.com/convertisseur-notes-sur-20",
    "applicationCategory": "Education",
    "tool": {
        "@type": "SoftwareApplication",
        "name": "Comment mettre une note sur 20 avec le Convertisseur de notes sur 20 en ligne",
        "description": "Un outil en ligne pour convertir les notes sur 20 en d'autres échelles de notation.",
        "operatingSystem": "Web",
        "applicationCategory": "Education",
        "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "USD"
        },
        "url": "https://notesur20.com/convertisseur-notes-sur-20.html"
    },
    "supply": {
        "@type": "HowToSupply",
        "name": "Disponibilité du service",
        "description": "Formulaire de conversion de note sur 20 en ligne disponibles 24/7."
    },
    "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": "USD"
    },
    "exampleOfWork": [
        {
            "@type": "ConvertAction",
            "description": "Convertir une note sur 14 sur 20. Exemple 7 sur 14",
            "fromValue": {
                "@type": "QuantitativeValue",
                "value": "7",
                "unitText": "sur 14"
            },
            "toValue": {
                "@type": "QuantitativeValue",
                "value": "10",
                "unitText": "sur 20"
            }
        },
        // Ajoutez les autres exemples ici...
    ],
    "howTo": {
        "@type": "HowTo",
        "name": "Comment convertir une note sur 14 en une note sur 20",
        "totalTime": "PT20S",
        "step": [
            {
                "@type": "HowToStep",
                "name": "Identifier la note initiale",
                "text": "Tout d'abord, déterminez la note que vous souhaitez convertir. Par exemple, si vous avez reçu une note de 12 sur 14 pour un devoir ou un examen, c'est la note que vous utiliserez pour la conversion.",
                "image": "https://notesur20.com/identifier-note.jpg"
            },
            {
                "@type": "HowToStep",
                "name": "Calculer l'équivalent sur 20",
                "text": "Pour trouver l'équivalent sur une échelle de 20 points, utilisez la formule : Note sur 20 = (Note sur 14 / 14) * 20.",
                "image": "https://example.com/equivalent-sur-20.jpg"
            },
            {
                "@type": "HowToStep",
                "name": "Arrondir si nécessaire",
                "text": "Selon vos préférences ou les exigences spécifiques, vous pouvez choisir d'arrondir le résultat à un chiffre entier ou à un nombre décimal spécifique.",
                "image": "https://notesur20.com/arrondir.jpg"
            }
        ]
    },
    "image": "https://notesur20.com/logo-notesur20.png",
    "BreadcrumbList": {
        "@type": "BreadcrumbList",
        "inLanguage": "fr-FR",
        "datePublished": "2022-12-01T00:00:00+00:00",
        "dateModified": "2024-03-23T00:00:00+00:00",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "name": "Accueil",
                "item": "https://notesur20.com/"
            },
            {
                "@type": "ListItem",
                "position": 2,
                "name": "Convertisseur de notes sur 20",
                "item": "https://notesur20.com/convertisseur-notes-sur-20.html"
            }
        ]
    }
};

  const jsonLDString = JSON.stringify(jsonLD, null, 2);

  return (

    <Jumbotron
      style={{
        color: "#efefef",
        fontFamily: "arial",
        backgroundImage: `url(${BG}), linear-gradient(45deg,#aaa 50.9%,#000 60%)`,
        filter: "grayscale(80%)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundBlendMode: "multiply",
        backgroundRepeat: 'no-repeat',
        marginBottom: "0px"
      }} fluid
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Convertisseur pour mettre ou remettre une note sur 20</title>
        <meta name="description" content="Formualire en ligne de conversion de note sur 20" />
        <script type="application/ld+json">
          {jsonLDString}
        </script>
      </Helmet>
      <Container >
        
        <Container>
        
          <form>
            <Row>
              <Col xs lg="12" style={{ padding: "20px", textAlign: "center", color: "#efefef" }}>
                <Row>
                  <h3 style={{
                    paddingLeft: "10px",
                    letterSpacing: "5px",
                    color: "#efefef",
                    fontWeight: "bolder",
                    borderLeft: "10px solid #555555",
                  }}
                  > <span style={{ letterSpacing: "3px", fontSize: "10px", textTransform: "none" }}>
                      &nbsp;notesur20 &nbsp;
                    </span>
                    Convertisseur pour mettre une note sur 20</h3></Row>
              </Col>
         

              <Row style={{ padding: "20px", textAlign: "center", color: "#efefef" }}>

                <Col xs={6} lg={6} style={{ boxShadow: "1px 1px 1px 1px #efefef ", borderRadius: "5px 5px 5px 5px", }}>
                  <br /><PiNumberCircleOneFill style={{ fontSize: "5em", color: "orangered" }} /> <br /><b style={{ fontSize: "1.5em", color: "#efefef" }}>Entrez votre note</b>
                  <br /><br /><input
                    placeholder="Note obtenue"
                    title="Note obtenue"
                    id="note"
                    type="number"
                    className="form-control-lg did-floating-input"
                    value={note}
                    name="note"
                    onChange={handleInputNoteChange}
                    style={{ textAlign: "center", border: "3px solid orangered", padding: "5px", borderRadius: "5px", boxShadow: "0 0 5px 2px rgba(255, 69, 0, 0.5)" }}
                  />
                  <br />Saississez la note à convertir que vous souhaitez mettre sur 20 .
                </Col>

                <Col xs={6} lg={6} style={{ boxShadow: "1px 1px 1px 1px #efefef ", borderRadius: "5px 5px 5px 5px" }}>
                  <br /><PiNumberCircleTwoFill style={{ fontSize: "5em", color: "orangered" }} /> <br /><b style={{ fontSize: "1.5em", color: "#efefef" }}>Entrez la note maximale</b>
                  <br /><br />
                  <input
                    placeholder="Note maximale"
                    title="Note maximale"
                    id="barem"
                    type="number"
                    className="form-control-lg did-floating-input"
                    value={barem}
                    name="note"
                    onChange={handleInputBaremChange}
                    style={{ textAlign: "center", border: "3px solid orangered", padding: "5px", borderRadius: "5px", boxShadow: "0 0 5px 2px rgba(255, 69, 0, 0.5)" }}
                  />
                  <br />
                  Saississez le barème de l'évaluation pour obtenir votre résultat sur 20 points.
                </Col>                
              </Row>
              <Col xs={12} lg={6} style={{}}>      
                    <p id="res" style={{ textAlign: "center", color: "green", "boxShadow": "1px 2px 2px 2px", background: '#fff', border: "1px solid silver", borderRadius: '10px', padding: '10px' }}>
                      <span style={{}}>
                        {test(note, barem).result}
                      </span>
                    </p>
              </Col >
            </Row>
          </form>
        </Container>
        <br />
        <Container style={{ background: "#fff", textAlign: "center" }}> <br /> <SatisfactionForm /></Container>
        <br /><br /><br />
      </Container>
    </Jumbotron >

  );
}


export default ConvertNoteSur20;
