import React, { useState } from 'react';
import { Row, Col } from "react-bootstrap";
function SatisfactionForm() {
    const [satisfaction, setSatisfaction] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const handleSatisfactionClick = (value) => {
        setSatisfaction(value);
        setSubmitted(true);
    };

    return (
        <div style={{ background: "#fff", padding: "0px" }}>
            <Row>
                <Col lg="12" >
                    <h4 style={{ borderRadius: "10px", background: "transparent", color: "#333", textAlign: "center", padding: "15px" }}>Voici la formule pour obtenir votre note sur 20 <br /><br />
                        <b>votre note / note maximale x 20 </b></h4>
                </Col>
            </Row>
            <hr />

            {submitted ? (
                <div>
                    <p style={{ color: "#111", fontWeight: "bold" }}>Merci pour votre avis !</p></div>
            ) : (
                <div>
                    <p style={{ color: "#000" }}>Avez-vous trouvé notesur20 utile ?</p>
                    <div>

                        <a href="//hoowuliz.com/4/6860981" target="_blank" rel="noopener noreferrer">
                            <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Pas du tout satisfait" onClick={() => handleSatisfactionClick(1)}>😞</span>
                        </a>
                        <a href="nabauxou.net/4/6860983" target="_blank" rel="noopener noreferrer">
                            <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Peu satisfait" onClick={() => handleSatisfactionClick(2)}>😐</span>
                        </a>
                        <a href="offmantiner.com/4/6860984" target="_blank" rel="noopener noreferrer">
                            <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Satisfait" onClick={() => handleSatisfactionClick(3)}>😊</span>
                        </a>
                        <a href="vaitotoo.net/4/6860995" target="_blank" rel="noopener noreferrer">
                            <span role="img" style={{ "cursor": "pointer", fontSize: "2em" }} aria-label="Très satisfait" onClick={() => handleSatisfactionClick(4)}>😃</span>
                        </a>
                        <input type="hidden" value={satisfaction} name="satisfaction" />

                    </div>
                </div>
            )}
        </div>
    );
}

export default SatisfactionForm;
