import React, { Component } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Jumbotron from 'react-bootstrap/Jumbotron';
import BG from "./bg.jpeg";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";


class About extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };

  }
  render() {
    return (
      <div>
        <Jumbotron
        style={{
          color: "#fff",
          fontFamily: "arial",
          backgroundImage: `url(${BG}), linear-gradient(45deg,#aaa 39.9%,#000 60%)`,
          filter: "grayscale(80%)",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundBlendMode: "multiply",
          backgroundRepeat: 'no-repeat',
          marginBottom: "0px"
        }} fluid>

        
        <Container >
          <Row style={{ paddingTop: "20px" }}>

            <Col xs lg="12">
              <br /><h1
                style={{
                  paddingLeft: "10px",
                  letterSpacing: "5px",
                  color: "#efefef",
                  fontWeight: "bolder",
                  borderLeft: "10px solid #555555"
                }}
              >Comment mettre une note sur 20 ?</h1>
              <p style={{ textAlign: 'justify', padding: '10px' }}>
                Vous cherchez à comprendre comment convertir ou ajuster vos notes sur une échelle de 20 ? Notre plateforme simplifie ce processus en vous offrant un outil intuitif pour transformer vos résultats. Que vous souhaitiez passer une note, la remettre sur 20 ou même calculer votre score final, notre service vous permet d'effectuer ces conversions aisément. Nous vous accompagnons pas à pas pour rapporter vos évaluations initiales sur l'échelle de notation de 20 points. Avec des fonctionnalités conviviales, telles que la possibilité de mettre, ramener ou convertir une note sur 20, notre plateforme vous offre la flexibilité nécessaire pour gérer efficacement vos résultats. Essayez dès maintenant notre convertisseur de notes sur 20 et optimisez votre évaluation selon vos besoins spécifiques.</p>
<hr />
    <h2>Exemple : Guide pour Convertir une Note sur 14 en Note sur 20</h2>
    <p>Découvrez comment convertir facilement une note sur 14 en une note sur 20 avec notre convertisseur en ligne. Notre guide pas à pas vous montre comment effectuer cette conversion de manière précise et efficace, vous permettant ainsi de comparer vos résultats et de mieux comprendre votre performance académique. Convertir vos notes n'a jamais été aussi simple !</p>
    <h3>Comment Convertir une Note sur 14 en note sur 20</h3>
    <p>Vous avez peut-être rencontré des systèmes de notation où les notes sont attribuées sur une échelle de 14 points. Cependant, pour une comparaison plus universelle ou une meilleure compréhension de vos performances, il peut être utile de convertir ces notes en une échelle de 20 points, plus couramment utilisée dans de nombreux contextes académiques.</p>
    <ol>
        <li><strong>Identifier la note initiale :</strong> Tout d'abord, déterminez la note que vous souhaitez convertir. Par exemple, si vous avez reçu une note de 12 sur 14 pour un devoir ou un examen, c'est la note que vous utiliserez pour la conversion.</li>
        <li><strong>Calculer l'équivalent sur 20 :</strong> Pour trouver l'équivalent sur une échelle de 20 points, utilisez la formule de conversion suivante :
            <br /><b>Note sur 20 = (Note sur 14 / 14) * 20</b></li>
        <li><strong>Arrondir si nécessaire :</strong> Enfin, selon vos préférences ou les exigences spécifiques, vous pouvez choisir d'arrondir le résultat à un chiffre entier ou à un nombre décimal spécifique.</li>
    </ol>
    <p>Avec notre convertisseur en ligne, vous pouvez effectuer ces calculs rapidement et facilement, obtenant ainsi une conversion précise et fiable de vos notes. Que ce soit pour des devoirs, des examens ou des évaluations, notre outil vous aide à interpréter vos résultats de manière plus complète, vous permettant ainsi de mieux suivre vos progrès et de prendre des décisions éclairées pour votre parcours académique.</p>

            </Col>

          </Row>
          <LinkContainer
            to="/convertisseur-de-note-sur-20.html"
            style={{ textAlign: "center" }}
          >
            <Nav.Link to="/convertisseur-de-note-sur-20.html">
              <Button variant="danger" style={{ width: "80%", minHeight: "4em" }}>
                Accédez maintenant au convertisseur de notes sur 20 <span role="img" aria-label="go convert">😃</span>
              </Button>
            </Nav.Link>
          </LinkContainer>
          <Row style={{ paddingTop: "20px" }}>


          </Row>
        </Container>
      </Jumbotron>

        <Jumbotron style={{ backgroundColor: '#efefef', marginBottom: "0" }} fluid>
          <Container>
            <Row style={{ padding: '10px' }}>
              <h3 style={{
                paddingLeft: "10px",
                letterSpacing: "3px",
                textTransform: "uppercase",
                color: "#555555",
                borderBottom: "1px #555555 solid",
                fontWeight: "bolder",
                borderLeft: "10px solid #555555",
                textAlign: "left"
              }}
              >
                <span style={{ letterSpacing: "3px", fontSize: "10px", textTransform: "none" }}>
                  &nbsp;notesur20 &nbsp;
                </span>Nos engagements </h3>
            </Row>
            <Col xs={12} lg={12}>
              <p style={{ textAlign: 'justify', padding: '10px' }}>
                Bienvenue sur notre plateforme d'outils éducatifs,
                où nous nous efforçons de faciliter la vie des étudiants et des enseignants.
                Notre tout nouvel outil de <b>conversion de notes sur 20 points</b> est conçu pour vous permettre de convertir
                facilement des notes provenant de différents systèmes de notation en une échelle commune sur 20 points.
                Que vous soyez un étudiant cherchant à évaluer vos performances ou un enseignant souhaitant établir des équivalences,
                notre outil vous offre une solution simple et précise.
              </p>
            </Col>
            <Row>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Rapidité</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Conversion instantanée</Card.Subtitle>
                  <Card.Text>
                    Notre outil de conversion offre des résultats précis et rapides en convertissant instantanément les notes reçues sur diverses échelles (ex. : 10 points, 100 points, 800 points, 44 points ....) en une note sur 20 points standard française.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Précision garantie</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Précis au centième près</Card.Subtitle>
                  <Card.Text>
                    Nous avons développé cet outil pédagogique pour assurer la précision et la fiabilité des conversions au centième.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Flexibilité</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Adaptable à tout context de notation</Card.Subtitle>
                  <Card.Text>
                    Que ce soit pour les examens, les devoirs ou les travaux pratiques, notre outil prend en charge toutes les formes d'évaluation pour vous offrir une expérience d'utilisation sans tracas.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row>
            <br />
            <Row>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Gratuit</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Utilisable sans contrainte</Card.Subtitle>
                  <Card.Text>
                    Les services et le convertisseur développés par notesur20.com sont gratuit et libre d'utilisation.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Convivialité</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Facile d'utilisation</Card.Subtitle>
                  <Card.Text>
                    Notre interface utilisateur intuitive facilite la saisie des notes et la consultation des résultats, même pour les utilisateurs novices en technologie.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="col-lg-4 d-flex align-items-stretch">
                <Card.Body>
                  <Card.Title>Protection des données</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Respect de la vie privée</Card.Subtitle>
                  <Card.Text>
                    Vos informations sont traitées de manière confidentielle, et nous ne conservons aucune donnée personnelle une fois la conversion effectuée.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Row> 
            </Container>
            </Jumbotron>
            <Jumbotron style={{ backgroundColor: '#888', marginBottom: "0" ,color:"#fff"}} fluid>
            <Container>
            <Row style={{}}> <h3
              style={{
                paddingLeft: "10px",
                letterSpacing: "3px",
                textTransform: "",
                color: "#efefef",
                borderBottom: "1px #555555 solid",
                fontWeight: "bolder",
                borderLeft: "10px solid #555555",
                textAlign: "left"
              }}
            >Choisissez de lire nos articles concernant la Note sur 20</h3>
            <br />
            
              <Col xs lg="6">
                <LinkContainer
                  to="/histoire-de-la-note-sur-20.html"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    window.open('//bauptost.net/4/6861103', '_blank', 'noopener,noreferrer');
                  }}
                >
                  <Nav.Link to="/histoire-de-la-note-sur-20.html">
                    <Button variant="success" style={{ width: "80%" }}>
                      L'histoire de la note sur 20
                    </Button>
                  </Nav.Link>
                </LinkContainer>

              </Col>
              <Col xs lg="6">
                <LinkContainer
                  to="/les-alternatives-a-la-note-sur-20.html"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    window.open('//greewepi.net/4/6861104', '_blank', 'noopener,noreferrer');
                  }}
                >
                  <Nav.Link to="/les-alternatives-a-la-note-sur-20.html">
                    <Button variant="success" style={{ width: "80%" }}>
                      Les alternatives à la note sur 20
                    </Button>
                  </Nav.Link>
                </LinkContainer>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </div >
    );
  }
}

export default About;
