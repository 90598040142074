import React from "react";
import { Navbar, Nav } from "react-bootstrap"; // NavDropdown
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Image from "react-bootstrap/Image";
import MainLogo from "../Medias/logo-notesur20.png";




const NavBar = () => {

  return (
    <Navbar
      className="sticky-top navbar-dark bg-dark"
      collapseOnSelect
      expand="lg"
      style={{
        fontSize: "0.8em",
        fontWeight: "bold",
        background: "#000"
      }}
    >
      <Navbar.Brand>

        <Link title="Retour à l'accueil note sur 20" to="/">
          <Image
            thumbnail={false}
            style={{ height: "30px", width: "110px" }}
            src={MainLogo}
            rounded={true}
            alt="notesur20.com"
          />
        </Link>
      </Navbar.Brand>



      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav" >
        <Nav className="ml-auto" style={{ margin: "0", marginLeft: "20px", padding: "0" }} >
          <Nav.Link
            title="Transformer une note sur 20 "
            to="/convertisseur-de-note-sur-20.html"
            href="/convertisseur-de-note-sur-20.html"
            style={{ margin: "0", padding: "0", marginLeft: "10px" }}
          >
            <sup>Outil</sup>&nbsp;Convertir une note sur 20
          </Nav.Link>
           <Nav.Link
            title="L'Histoire de la note sur 20"
            to="/histoire-de-la-note-sur-20.html"
            href="histoire-de-la-note-sur-20.html"
            style={{ margin: "0", padding: "0", marginLeft: "10px" }}
          >
            <sup>Lire</sup>&nbsp;Histoire de la note sur 20
          </Nav.Link>
          <Nav.Link
            title="Les alternatives à la Note sur 20.html"
            to="/les-alternatives-a-la-note-sur-20.html"
            href="/les-alternatives-a-la-note-sur-20.html"
            style={{ margin: "0", padding: "0", marginLeft: "10px" }}
          >
            <sup>Lire</sup>&nbsp;Alternatives à la note sur 20
          </Nav.Link>

        </Nav>
      </Navbar.Collapse>
      <Nav className="ml-auto">
        <Nav.Link href="https://linktr.ee/drwily" target="_blank" rel="noopener noreferrer">
          <Image
            src="drwil.jpg"
            style={{
              height: "40px", width: "40px", borderRadius: "50%", marginRight: "15px", border: "2px solid transparent",
            
            }}
            alt="L'auteur"
            title="Site de l'auteur"
          />
        </Nav.Link>
      </Nav>
    </Navbar>

  );
};

export default NavBar;
