import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import TopNavBar from "./Components/TopNavBar";
import Legal from "./Components/Legal";
import CookieConsent from "./Components/CookieConsent";
import Footer from "./Components/Footer";
import Histoire from "./Components/Histoire";
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "./Components/404";
import { AnimatedSwitch } from 'react-router-transition';
import About from "./Components/About";
import Alternative from "./Components/Alternative";
import ConvertNoteSur20 from "./Components/ConvertNoteSur20";




const App = () => {

  return (

    <Router>
      <CookieConsent />
      <TopNavBar />
      <ScrollToTop />
      <AnimatedSwitch
        atEnter={{ offset: -100 }}
        atLeave={{ offset: 0 }}
        atActive={{ offset: 0 }}
        mapStyles={(styles) => ({
          transform: `translateX(${styles.offset}%)`,
        })}
        className="switch-wrapper">
        <Route exact path="/" render={(props) => <About />}>
          <About />
        </Route>
        <Route path="/histoire-de-la-note-sur-20.html" component={() => <Histoire />}></Route>
        <Route path="/convertisseur-de-note-sur-20.html" component={() => <ConvertNoteSur20 />}>
          <ConvertNoteSur20 />
        </Route>
        <Route path="/les-alternatives-a-la-note-sur-20.html" component={() => <Alternative />}>
          <Alternative />
        </Route>
        <Route path="/Legal.html">
          <Legal />
        </Route>

        <Route component={NotFound} />
      </AnimatedSwitch>
      <Footer />
    </Router>
  );

}

export default App;
