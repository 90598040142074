import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Button } from "react-bootstrap";
import { Nav } from "react-bootstrap"; // NavDropdown
import Jumbotron from 'react-bootstrap/Jumbotron';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (

    <Container>
      <Jumbotron style={{ marginTop: "20px", paddingTop: "0px" }}><div
        className="ml-auto fixed-bottom"
        style={{ color: "#888", fontSize: "12px", backgroundColor: "#111111", textAlign: "center" }}
      ><br />
        <a href="//groorsoa.net/4/6861010" target="_blank" rel="noopener noreferrer">
          <Button variant="warning" style={{ width: "50%", fontWeight: "bolder" }}>
            <FontAwesomeIcon icon={faHandHoldingHeart} style={{ marginRight: '5px' }} /> LET'S PLAY
          </Button>
        </a>
        <br /><br />

        <Nav.Link
          title="L'Histoire de la note sur 20"
          to="/histoire-de-la-note-sur-20.html"
          href="histoire-de-la-note-sur-20.html"
          style={{ display: "inline", color: "#aaaaaa" }}
        >
          Histoire de la note sur 20
        </Nav.Link>
        <Nav.Link
          title="Les alternatives à la Note sur 20.html"
          to="/les-alternatives-a-la-note-sur-20.html"
          href="/les-alternatives-a-la-note-sur-20.html"
          style={{ display: "inline", color: "#aaaaaa" }}
        >
          Alternatives à la note sur 20
        </Nav.Link>
        <Nav.Link
          title="Transformer une note sur 20 "
          to="/convertisseur-de-note-sur-20.html"
          href="/convertisseur-de-note-sur-20.html"
          style={{ display: "inline", color: "#aaaaaa" }}
        >
          Convertir note sur 20
        </Nav.Link>
        <Nav.Link
          title="Mentions légales de notesur20.com"
          to="/legal.html"
          href="/legal.html"
          style={{ display: "inline", color: "#aaaaaa" }}
        >
          Mentions légales
        </Nav.Link>
        <b>©2023 notesur20 </b> &nbsp;&nbsp;&nbsp;
      </div></Jumbotron>
    </Container>
  );
};

export default Footer;
